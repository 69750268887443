.rowFooter {
  background: rgba(81, 107, 113, 0.13);
  display: flex;
  padding: 20px 0px;
  height: 476px !important;
  color: #535252;
}

.leftP {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 50px;
  font-family: Poppins;
  color: #535252;
}

.leftPBottom {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 50px;
  font-family: Poppins;
  color: #535252;
}

.secondColFooter {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6rem !important;
  font-size: 14px !important;
  line-height: 21px;
  letter-spacing: 0.04em;
  padding-top: 5%;
  color: #535252;
}

#SecondFooterRow {
  display: inline-flex;
  width: 100%;
  margin: auto;
  justify-content: space-evenly;
}

#SecondFooterBottomRow {
  display: flex;
  width: 70%;
  padding-top: 10px;
  margin: auto;
  justify-content: space-evenly;
  text-align: center;
}

.contactUsFooter {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
  color: #535252;
  padding-top: 15%;
  font-family: Poppins;
}

.contactTime {
  font-family: Poppins;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #535252;
}

.subtextfooter {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #535252;
  font-family: Poppins;
}
.social {
  display: flex;
  margin-top: 65px !important;
}
.marginRight20 {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactInfor {
  display: flex;
  margin-top: 70px;
}
.ownedBy {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 1%;
  color: #535252;
  text-decoration: underline;
  font-family: Poppins;
}
.license {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 1%;
  color: #535252;
  padding-left: 50px;
  margin-bottom: 0 !important;
  font-family: Poppins;
}
.lic {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 1%;
  color: #535252;
  padding-left: 50px;
  font-family: Poppins;
}
.footerLeft {
  border-right: 1px solid #737171;
}
.footerMenu {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 50px;
  .marginAuto {
    margin-bottom: 30px;
  }
  a {
    color: #535252;
  }
}
.firstPart {
  border-bottom: 1px solid #737171;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #535252;
  a {
    color: #535252;
  }
}
.secondPart {
  text-align: center;
  margin-top: 30px;
  a {
    color: #535252;
  }
}
.mobilesocial {
  width: 60% !important;
  padding-top: 10px;
  margin: auto;
  justify-content: space-evenly;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #535252;
}
.mobileFooter {
  background: rgba(81, 107, 113, 0.13);
  padding: 28px 20px 40px 20px;
  display: none;
}
.contactUs {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #535252;
}
.rowFooter {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 150;
  color: #535252;
}

.mobileIconText {
  font-size: 12px;
  font-family: Poppins;
  line-height: 14px;
}
@media only screen and (max-width: 768px) {
  .mobileFooter {
    display: block;
  }
  .rowFooter {
    display: none;
  }
  .secondColFooter {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #535252;
  }
}

.rowFooter {
  a {
    color: #535252;
  }
}

.footerIcon {
  cursor: pointer;
}
